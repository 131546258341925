import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/score.vue"),
  },
  {
    path: "/score",
    name: "Score",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/score.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
